.question {
  color: #000;
  font-size: 25px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: normal;
  text-decoration-line: underline;
  margin-bottom: 5px;
}

.answer {
  color: #000;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 40px !important;
}

a:not(.btn-link, .btn-link-component):not(.MuiButtonBase-root) {
  color: #3959fa;
  padding: 2px;
}

.btn-link {
  color: inherit !important;
  text-decoration: none;
}
